<template>
    <Modal v-if="Object.keys(stock).length > 0" @close="close()" :heading="'Stock Curb'">
        <template v-slot:body>
            <div title="Stock Curb">
                <form>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Location</th>
                                <th>Available</th>
                                <th>Allocated</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in stock.quantity" v-bind:key="index">
                                <td>{{ row.cdi_location }}</td>
                                <td>{{ row.available }}</td>
                                <td>{{ row.allocated }}</td>
                                <td>{{ row.total }}</td>
                            </tr>
                            <tr v-if="stock.move_it">
                                <td class="bg-danger" colspan="4">Move it</td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </template>
        <template v-slot:footer>
            <div class="form-inline" v-if="showForm">
                <label>
                    Quantity
                    <input type="text" v-model="formQuantity" class="form-control">
                </label>
                <input class="cdi_button btn btn-default" type="button" @click="allocate" value="Take from Stock">
            </div>
        </template>
    </Modal>
</template>

<script>
    import { store } from '@/store/BuildScheduler.store';
    import helpers from "@/store/helpers";
    import Modal from "@/components/utils/Modal";

    export default {
        name: "CurbStockDialog",
        props: ['user', 'trackerCurb'],
        components: {Modal},
        data() {
            return {
                state: store.state,
                stock: {},
                formQuantity: null
            }
        },
        methods: {
            allocate: function () {
                let params = {
                    'params[quantity]': parseInt(this.formQuantity),
                    'params[tracker_id]': this.trackerCurb.tracker_id,
                    'params[curb_id]': this.trackerCurb.curb_id,
                    ...store.getFilterParameters()
                };

                store.api('/take_curb_from_stock', params).then(result => {
                    if (result.opt !== 'error') {
                        helpers.nestedSet(this.state, 'buildSchedule', result);
                    }
                    this.close();
                });
            },
            close: function () {
                this.stock = {};
                this.formQuantity = null;
                this.$emit('closeStockDialog');
            },
            showForm: function () {
                return this.user.roles.includes(0) || this.user.roles.includes(8);
            }
        },
        watch: {
            trackerCurb: function () {
                if (!Object.keys(this.trackerCurb).length > 0) {
                    return;
                }

                store.customAPI(`/curb/get_stock/${this.trackerCurb.curb_id}`).then(result => this.stock = result);
            }
        }
    }
</script>

<style scoped>

</style>