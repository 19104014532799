import appStore from "@/store/App.store";
import helpers from "@/store/helpers";
import http from "axios";
import {cdiVars} from "@/cdiVars";

export const store = {
    state: {
        task: {
            taskID: 0,
            label: '',
        },
        tasks: {
            master: {
                taskID: 0,
                label: 'Master',
            },
            sales: {
                taskID: 26,
                label: 'Sales',
            },
            materials: {
                taskID: 30,
                label: 'Special Materials',
            },
            engineering: {
                taskID: 22,
                label: 'Engineering',
            },
            checking: {
                taskID: 25,
                label: 'Eng Checking',
            },
            radan: {
                taskID: 31,
                label: 'Radan',
            },
            batching: {
                taskID: 21,
                label: 'Batching',
            },
            turret: {
                taskID: 11,
                label: 'Turret',
            },
            folding: {
                taskID: 17,
                label: 'Folding',
            },
            clinching: {
                taskID: 33,
                label: 'Clinching',
            },
            shell: {
                taskID: 5,
                label: 'Shell',
            },
            guts: {
                taskID: 29,
                label: 'Guts',
            },
            qc: {
                taskID: 24,
                label: 'Quality Control',
            },
            insulate: {
                taskID: 7,
                label: 'Insulate',
            },
            crate: {
                taskID: 8,
                label: 'Crate',
            },
            loading: {
                taskID: 14,
                label: 'Loading',
            },
            finalpaperwork: {
                taskID: 28,
                label: 'Final Paperwork',
            },
            measure: {
                taskID: 29,
                label: 'Measure',
            },
        },
        batchMode: false,
        showGauge: false,
        batchGroup: null,
        batchTrackers: [],
        batchTrackerInvoices: [],
        curbsToBatch: [],
        buildSchedule: {
            days: {}
        },
        formFields: {
            cdiLocationID: {val: 0, qsField: '[cdi_location_id]'},
            scheduleDate: {val: 'build_date', qsField: '[schedule_date]', defaultVal: 'build_date'},
            curbTypeID: {val: 0, qsField: '[numbers][c.curb_type_id]', defaultVal: 0},
            conforming: {val: null, qsField: '[isset][conforming]', defaultVal: null},
            applied: {val: null, qsField: '[isset][applied]', defaultVal: null},
            sectional: {val: null, qsField: '[isset][sectional]', defaultVal: null},
            insulationKit: {val: null, qsField: '[isset][insulation_kit]', defaultVal: null},
            batchingMachine: {val: 0, qsField: '[strings][tg.machine_name]', defaultVal: 0},
            curbPoints: {val: null, qsField: '[curb_points]', defaultVal: null},
            shiptoAddress: {val: null, qsField: '[shipto_address]', defaultVal: null},
            sortState: {val: null, qsField: '[sort_state]', defaultVal: null},
            curbCounts: {val: 0, qsField: '[curb_counts]', defaultVal: 0},
            truckLoads: {val: 0, qsField: '[numbers][trucking_options_id]', defaultVal: 0},
            onHold: {val: 0, qsField: '[on_hold]', defaultVal: 0},
            hasSpecialMaterials: {val: 0, qsField: '[isset][has_special_materials]', defaultVal: 0},
        }
    },

    post: async function(url, qsPairs, resultField){
        appStore.loadingMessage();

        let qsString = helpers.getQSString(qsPairs);
        return await http.post(url, qsString, { headers: helpers.authHeader() })
            .then(res => this.handleResponse(resultField, res.data))
            .catch();
    },
    /**
     * if resultField is a string, this.state.string is updated with the data
     * if resultField is undefined, the data is returned
     */
    handleResponse: function(resultField, data) {
        if (data.opt == 'denied') {
            appStore.autoSignOut();
        }else if (data.opt == 'error') {
            appStore.errorBox(data.msg);
            return data;
        } else {
            appStore.successBox(data.msg);
            if (typeof resultField === 'undefined') {
                return data;
            } else {
                helpers.nestedSet(this.state, resultField, data);
            }
        }
    },

    api: function(endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/buildsch/${endPoint}`, options, resultField))
        );
    },
    customAPI: function(endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/${endPoint}`, options, resultField))
        );
    },
    getFilterParameters: function () {
        let options = {
            'options[task_id]': this.state.task.taskID,
            'options[master_only]': 0
        };

        let qsPairs = helpers.getQSPairs(this.state.formFields, 'options');

        return {
            ...options,
            ...qsPairs
        };
    },
    getData: function() {
        store.api('buildsch_json', this.getFilterParameters(), 'buildSchedule');
    },
    explodeBarcode: function(barcode) {
        let ids = {trackerID: 0};
        let txt = barcode.substr(1);
        
        let chunk_one = txt.split('I');
        ids.trackerID = chunk_one[0];
        
        let chunk_two = chunk_one[1].split('C');
        ids.trackerInvoiceID = chunk_two[0];
        
        let chunk_three = chunk_two[1].split('S');
        ids.curbID = chunk_three[0];
        
        return ids;
    },

    getMaintenanceData: function (options) {
        store.customAPI('asset/maintenance_scheduler_json', options, 'buildSchedule');
    },
}
