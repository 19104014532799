<template>
    <div class="table-responsive">
        <Notebook :drawingRequestID="activeDrawingRequestID" @clearNotes="clearNotes"/>

        <table class="table">
            <tbody>
            <tr>
                <th>Item</th>
                <th>Quote/Order</th>
                <th>State</th>
                <th class="sortable" @click="sort('p.price_level_id', true)">Company</th>
                <th>Submitted By</th>
                <th class="sortable" @click="sort('dr.drawing_request_date', true)">Time</th>
                <th>Total Queue Time</th>
                <th>Drafter(s)</th>
                <th>Time</th>
                <th>Complete</th>
                <th>Has PO</th>
            </tr>
            <tr v-for="request in this.requests" v-bind:key="request.drawing_request_id">
                <td>
                    {{ request.nomenclature }}
                    <span class="badge badge-secondary">
                            {{ cache.drawingTypeCache.DRAWING_TYPES[request.drawing_type_id] }}
                        </span>
                    <span v-show="request.applied || request.t_applied">
                            <em>Applied</em>
                        </span>

                </td>
                <td>
                        <span v-if="request.quote_id">
                            Q:
                            <router-link :to="`/quote/${request.quote_id}`">
                                {{ request.quote_id }}
                            </router-link>
                        </span>
                    <span v-else-if="request.tracker_id">
                            T:
                            <router-link :to="`/tracker/${request.tracker_id}`">
                                {{ request.tracker_id }}
                            </router-link>
                        </span>

                    <button class="btn btn-default" @click="activeDrawingRequestID = request.drawing_request_id">
                        Notes
                    </button>

                    <span v-show="request.auto_draw_eligible">
                            FD
                        </span>
                </td>
                <td>{{ request.distributor_state }}</td>
                <td>
                    {{ request.company_name }}
                    <em>{{ request.price_level }}</em>
                </td>
                <td>{{ request.submited_by }}</td>
                <td>{{ request.drawing_request_date }}</td>
                <td>{{ request.elapsed_time }}</td>
                <td>{{ request.drafter }}</td>
                <td>{{ request.draft_sec }}</td>
                <td v-if="request.missing_info == 0">
                        <span
                            v-if="!request.completed_time && !parseInt(request.draft_cur_sec) && request.missing_info == 0">
                            <button class="btn btn-default btn-block"
                                    @click="start(request.drawing_request_id)">Start</button>
                        </span>
                    <span
                        v-else-if="!request.completed_time && parseInt(request.draft_cur_sec) || request.missing_info == 1">
                            <button v-if="request.missing_info == 0" class="btn btn-default btn-block"
                                    @click="stop(request.drawing_request_id)">Pause</button>
                            <button v-if="request.missing_info == 0" class="btn btn-default btn-block"
                                    @click="done(request.drawing_request_id)">Done</button>
                            <button v-if="request.missing_info == 0" class="btn btn-default btn-block"
                                    @click="missingInfo(request.drawing_request_id)">Missing Info</button>
                        </span>
                    <span v-else>
                            Complete
                        </span>
                </td>
                <td v-else-if="request.missing_info == 1">
                    <button class="btn btn-default btn-block" @click="infoProvided(request.drawing_request_id)">Missing Info Provided</button>
                </td>
                <td v-else>
                    <button class="btn btn-default btn-block" @click="resume(request.drawing_request_id)">Resume</button>
                </td>
                <td>{{ request.has_po }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { store } from "@/store/Tool.store";
    import Notebook from "./Notebook";
    import { drawScheduleStore } from '@/store/DrawScheduler.store.js';
    import  drawingTypeCache  from '@/cache/drawingType.cache.js';

    export default {
        name: "Container",
        components: {
            Notebook
        },
        props: ["requests"],
        data() {
            return {
                drawScheduleState: drawScheduleStore.state,
                activeDrawingRequestID: 0,
                sortDirection: 'DESC',
                cache: {
                    drawingTypeCache,
                },
            }
        },
        methods: {
            sort: function (field, changeSortDirection) {
                if(changeSortDirection){
                    this.sortDirection = this.sortDirection === 'DESC' ? 'ASC' : 'DESC';
                }
                let options = {
                    'params[cdi_location_id]': this.drawScheduleState.formFields.cdiLocationID.val,
                    'params[direction]': this.sortDirection,
                    'params[orderby]': field
                };

                drawScheduleStore.state.formFields.orderBy.val = field;
                drawScheduleStore.state.formFields.direction.val = this.sortDirection;

            store.api('/drawsch/open_requests', options)
                .then(results => this.drawScheduleState.data = results);
        },
        start: function (requestID) {
            let options = this.getOptions(requestID);
            store.api('/drawsch/start_drawing', options)
                .then(results => this.drawScheduleState.data = results);
        },
        stop: function (requestID) {
            let options = this.getOptions(requestID);

            store.api('/drawsch/stop_drawing', options)
                .then(results => this.drawScheduleState.data = results);
        },
        done: function (requestID) {
            let options = this.getOptions(requestID);

            store.api('/drawsch/complete_drawing', options)
                .then(results => this.drawScheduleState.data = results);
        },
        getOptions: function(requestID) {
          return {
            'params[drawing_request_id]': requestID,
            'params[cdi_location_id]': this.drawScheduleState.formFields.cdiLocationID.val,
            'params[direction]': this.drawScheduleState.formFields.direction.val,
            'params[orderby]': this.drawScheduleState.formFields.orderBy.val,
          };
        },
        missingInfo: function (requestID) {
            let options = {
                'params[drawing_request_id]': requestID,
                'params[cdi_location_id]': this.drawScheduleState.formFields.cdiLocationID.val,
                'params[direction]': this.drawScheduleState.formFields.direction.val,
                'params[orderby]': this.drawScheduleState.formFields.orderBy.val,
            };

          store.api('/drawsch/missing_info', options)
              .then(results => this.drawScheduleState.data = results);
        },
        infoProvided: function (requestID) {
            let options = {
                'params[drawing_request_id]': requestID,
                'params[cdi_location_id]': this.drawScheduleState.formFields.cdiLocationID.val,
                'params[direction]': this.drawScheduleState.formFields.direction.val,
                'params[orderby]': this.drawScheduleState.formFields.orderBy.val,
            };

            store.api('/drawsch/info_provided', options)
                .then(results => this.drawScheduleState.data = results);
        },
        resume: function (requestID) {
            let options = this.getOptions(requestID);

            store.api('/drawsch/resume_drawing', options)
                .then(results => this.drawScheduleState.data = results);
        },
        clearNotes: function () {
            this.activeDrawingRequestID = 0;
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
    .table-responsive {
        border: none!important;
    }
}
</style>