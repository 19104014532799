<template>
    <div v-show="drawingRequestID" class="spacing_btm">
        <h3>Notes</h3>
        <strong>{{ notes.custom_display }} {{ notes.nomenclature }}</strong>
        <div class="spacing_btm" v-html="notes.description"></div>
        <table class="table">
            <tbody>
            <tr>
                <th><span class="glyphicon glyphicon-ok"></span></th>
                <th>Note</th>
                <th>By</th>
            </tr>
            <tr v-for="note in notes.notes" v-bind:key="note.cdi_note_id">
                <td>
                    <span @click="read(note.cdi_note_id)" class="glyphicon glyphicon-ok cdi_link" :class="(note.checked || isUserInChecks(note.checks)) ? 'cdi_hide' : ''" title="check this note"></span>
                </td>
                <td>
                    <span :class="note.strikeout ? 'note_text strikeout' : 'note_text'">{{ note.note_txt }}</span>
                    <span v-for="checks in note.checks" :key="checks.check_dt">
                            <br>
                            <span class="glyphicon glyphicon-ok"></span> {{checks.checked_by}} ({{checks.check_dt}})
                        </span>
                </td>
                <td>
                    {{ note.note_user }}<br>
                    {{ note.cdi_note_date }}
                </td>
            </tr>
            </tbody>
        </table>
        <button class="btn btn-default spacing_top" @click="clearNotes">Hide</button>
    </div>
</template>

<script>
import {store} from "@/store/Tool.store";

export default {
    name: "Notebook",
    props: ['drawingRequestID'],
    data() {
        return {
            notes: {},
        }
    },
    methods: {
        getNotes: function() {
            let options = {
                'params[drawing_request_id]': this.drawingRequestID
            };

            store.api('/drawsch/notes', options)
                .then(results => {
                    this.notes = results;
                }
            );
        },
        read: function(cdiNoteID) {
            store.api('/drawsch/check_note', { 'params': cdiNoteID })
                .then(() => this.getNotes());
        },
        isUserInChecks: function(checks) {
            if (typeof checks === 'undefined' || checks.length === 0) {
                return false;
            }

            return checks.find(check => parseInt(check.user_id) === this.$appStore.state.user.userID);
        },
        clearNotes: function() {
            this.$emit('clearNotes');
        }
    },
    watch: {
        drawingRequestID: function() {
            if (this.drawingRequestID) {
                this.getNotes();
            } else {
                this.notes = {};
            }
        }
    }
}
</script>

<style scoped>
span.note_text {
    white-space: pre-line;
}
</style>