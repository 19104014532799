<template>
        <div v-if="loggedIn">
            <div :class="`fileUploadContainer ${ (expanded) ? 'fileDropzone' : ''}`"
                 @drag.prevent
                 @drop.prevent="dragUpload($event)"
                 @dragover.prevent="dragOver($event)"
                 @dragenter.prevent
                 @dragleave.prevent="dragLeave($event)"
                 @fileDropped="setFileUploadParameters">
            <Nav :task="state.task" :machine-names="state.buildSchedule.machine_names" class="col-lg-2 col-md-3 col-sm-3" />
            <main class="col-lg-10 col-md-9 col-sm-9 fill main">
                <h1>{{ state.task.label }}</h1>
                <Container :task="state.task" :buildSchedule="state.buildSchedule" :user="appState.user" />
            </main>
        </div>
        </div>
        <div v-else-if="!loggedIn">
            <div style="margin-left: 175px;">
                <SignInPage></SignInPage>
            </div>
        </div>
</template>

<script>
import Nav from '@/components/schedulers/buildsch/Nav';
import Container from '@/components/schedulers/buildsch/Container';
import { store } from '@/store/BuildScheduler.store';
import AppStore from "@/store/App.store";
import buildSchedulerCache from "@/cache/buildScheduler.cache";
import SignInPage from "@/views/SignInPage";
import {fileUploadStore} from "@/store/FileUpload.store";

export default {
    name: "BuildSchedulerPage",
    components: {
        Nav,
        Container,
        SignInPage
    },
    data() {
        return {
            state: store.state,
            appState: this.$appStore.state,
            expanded: false,
            filestate: fileUploadStore.state,
            cache: {
                buildSchedulerCache
            }
        }
    },
    props: ['taskName'],
    methods: {
        dragUpload: function(e) {
            this.$emit('fileDropped');

            // Collapse the file upload zone
            this.expanded = false;

            // Looping through the single instance of a file to extract data.
            // Populate metadata about the file itself in the stores.
            // Execute file submission
            if(!this.filestate.isModal) {
                if (e.dataTransfer.files.length) {
                    this.files = [];

                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        this.files.push(e.dataTransfer.files[i]);
                    }
                    fileUploadStore.setFile(this.files[0]);
                    fileUploadStore.setTitle(this.files[0].name);
                    this.submitFiles();
                } else {
                    this.$appStore.errorBox('No file detected.');
                }
            }
            this.filestate.isModal = false;
        },
        submitFiles(){
            fileUploadStore.uploadFile();
            this.setFileUploadParameters();
        },
        dragOver: function(e) {
            e.stopPropagation();
            this.expanded = true;
        },
        dragLeave: function(e) {
            e.stopPropagation();
            this.expanded = false;
        },
        setFileUploadParameters: function () {
            this.$appStore.setFileUploadContext('item_batch');
            this.$appStore.setFileUploadContextData({
                referenceID: this.state.batchGroup
            });
            this.$appStore.setFileUploadCallback(function () {});
        },

        defaultDate: function(taskName) {
            let defaultDate = 'build_date';
            if (taskName == 'engineering' || taskName == 'checking') {
                defaultDate = 'eng_due_date';
            } else if (taskName == 'loading' || taskName == 'finalpaperwork') {
                defaultDate = 'scheduled_ship';
            }

            return defaultDate;
        },
        defaultLocationID: function () {
            let nonDefaultLocationTasks = [
                this.state.tasks.sales.taskID,
                this.state.tasks.materials.taskID
            ];

            if (nonDefaultLocationTasks.includes(this.state.task.taskID)) {
                return 0;
            }

            return this.appState.user.schedulerLocation;
        }
    },
    computed: {
        loggedIn: function() {
            return this.appState.loggedIn
        },
    },
    watch: {
        taskName: {
            immediate: true,
            handler() {
                this.state.task = this.state.tasks[this.taskName];
                this.$appStore.setTitle([this.state.task.label, 'Build Scheduler']);
                this.state.formFields.scheduleDate.val = this.defaultDate(this.taskName);
                this.state.formFields.cdiLocationID.val =  this.defaultLocationID();
                this.state.buildSchedule = {};
                for (let i = 0; i < Object.keys(this.state.formFields).length; i++) {
                    if (Object.values(this.state.formFields)[i].defaultVal !== undefined) {
                        Object.values(this.state.formFields)[i].val = Object.values(this.state.formFields)[i].defaultVal;
                    }
                }

                store.getData();
            }
        }
    },
    created() {
        if (AppStore.state.user.userID in this.cache.buildSchedulerCache.DEFAULT_SCHEDULER_LOCATION) {
            this.state.formFields.cdiLocationID.val = this.cache.buildSchedulerCache.DEFAULT_SCHEDULER_LOCATION[AppStore.state.user.userID];
        } else {
            this.state.formFields.cdiLocationID.val = AppStore.state.user.schedulerLocation
        }
    }
}
</script>

<style scoped>
.col-lg-2{
    z-index: 900;
}
.fileUploadContainer {
    height: 100%;
    width: 100%;
}

.fileDropzone {
    opacity: .5;
}

</style>
