<template>
    <div class="sch_tracker" v-show="showTracker">
        <div v-for="(alert, id) in trackerData.alerts" :key="id" :class="['sch_row', 'sch_col_alert', trackerData.truck]">{{ alert }}</div>
        <div :class="[trackerData.truck, 'sch_row']" style='border-top:0px'>
            <div class="sch_col sch_tracker_id">
                <router-link :to="`/tracker/${trackerData.tracker_id}`">{{ trackerData.tracker_id }}</router-link>
            </div>
            <div class="sch_col right">
                <span style="margin-left: -15px; padding-right: 5px;">${{ trackerData.total_cost }}</span>
                {{ scheduledShipOrLocation }}
            </div>
        </div>
        <div :class="[trackerData.truck, 'sch_row']">
            <div class="sch_col">{{ trackerData.company_name }}</div>
            <div class="sch_col right">{{ trackerData.job_tag }}</div>
        </div>

        <!-- Shipping -->
        <div v-if="trackerData.shipping_co" :class="[trackerData.truck, 'sch_row']">
            <div class="sch_col">{{ trackerData.shipping_co }}</div>
            <div v-if="trackerData.shipto_state" class="sch_col right">{{ trackerData.shipto_city }}, {{ trackerData.shipto_state }} {{ trackerData.shipto_zip }}</div>
            {{ trackingProNum }}
            <div v-for="trackerID in trackerData.consolidated"  :class="(trackerData.consolidated) ? 'consolidated' : ''" :key="trackerID"><router-link :to="`/tracker/${trackerID}`">{{ trackerID }}</router-link></div>
            <div class='sch_col' style='width:100%'>{{ trackerData.truck_booked }}</div>
        </div>

        <!-- Call Ahead -->
        <div v-if="trackerData.call_ahead && !trackerData.call_ahead_complete" class="sch_row cdi_item right">Call Ahead Needed</div>
        <div v-if="trackerData.call_ahead && trackerData.call_ahead_complete" class="sch_row cdi_item right">Call Ahead Completed</div>

        <!-- Contact -->
        <div v-if="trackerData.contact_phone" :class="[trackerData.truck, 'sch_row']">
            <div class="sch_col">{{ trackerData.contact_name }}</div>
            <div class="sch_col" style='text-align:right;'>{{ trackerData.contact_phone }}</div>
        </div>

        <!-- Curbs -->
        <div v-show="showTrackerInvoice(curb.tracker_invoice_id)" :class="['sch_row', 'cdi_item', curb.task]" v-for="curb in trackerData.curbs" :key="curb.tracker_invoice_id">
            <div class="sch_col" style="width:25%;">
                <input :class="['batch', `group${curb.group_id}`]" type='checkbox' v-show="state.batchMode && state.task.label !=='Turret'" v-model="state.curbsToBatch" :value="curb.tracker_invoice_id">
                {{ curb.line }}
                <div v-if="curb.curb_variant_id" class="badge badge-primary"> v{{ curb.curb_variant_id }}</div>
                <a v-if="curb.in_stock" @click="showStockDialog(curb)" :class="['cdi_link', 'stock_available_link', `stock_${curb.in_stock}`]">S</a>
                <div v-if="curb.over_sized" class="badge badge-oversize"> OS #{{ curb.tracker_skid_id }}</div>
                {{ insulationKit(curb.insulation_kit) }}
                {{ hem(curb.is_hem) }}
                {{ altBraceKit(curb.alt_brace_kit) }}
            </div>
            <div class='sch_col'>{{ curb.custom_disp }}<router-link :to="`/curb/${curb.curb_id}`">{{ tf(curb.turret_ready, curb.not_kit) }} {{ curb.nomenclature }}</router-link> {{ curbPoints(curb.curb_points) }}</div>
            <div class="sch_col" style="text-align:right;width:25%;">
                <span>{{ curb.time_to_completion }}</span>
                <span class="cdi_clickable cdi_link" @click="showTaskDialog(curb)"> @{{ curb.task }}</span>
            </div>
            <div v-if="curb.has_active_drawing"><span style="color: red">Open Drawing Request</span></div>
            <div v-if="curb.status" style="text-align:right"><i>{{ curb.status }}</i></div>
            <div v-for="rework in curb.reworks" :key="rework.curb_rework_id" :class="{disabled: rework.disabled}">
                Rework: {{ rework.rework_description }}, {{rework.cause_of_error}}, {{rework.items_needed_for_batch}}, {{ rework.rework_notes }} by {{ rework.employee }} @ {{ rework.rework_date }};
            </div>
            <div v-if="curb.group_id">
                <strong>Item Batch #{{ curb.group_id }}</strong> {{ curb.machine_name }}
            </div>
            <div v-if="curb.stock">Stock #{{ curb.stock }}</div>
            <div v-if="curb.from_stock">From Stock</div>
            <div v-if="curb.gauge && state.showGauge" class="sch_col">
                <span>Gauge: {{curb.gauge}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/BuildScheduler.store';

    export default {
    name: "Tracker",
    props: ['trackerData'],
    data() {
        return {
            state: store.state
        }
    },
    methods: {
        tf: function(turretReady, notKit) {
            let text = '';
            if (turretReady) {
                text = 'T';
                if (notKit) {
                    text += 'F';
                }
            }
            return text;
        },
        curbPoints: function(curbPoints) {
            return curbPoints ? `${curbPoints}pts` : '';
        },
        insulationKit: function(insulationKit) {
            return insulationKit ? 'INS' : '';
        },
        hem: function(isHem) {
            return isHem ? 'HEM' : '';
        },
        altBraceKit: function(altBraceKit) {
            return altBraceKit ? 'ALT' : '';
        },
        showTaskDialog: function(trackerCurb) {
            trackerCurb.tracking_pro_num = this.trackerData.tracking_pro_num;
            this.$emit('taskDialog', trackerCurb);
        },
        showStockDialog: function(trackerCurb) {
            trackerCurb.tracker_id = this.trackerData.tracker_id;
            this.$emit('stockDialog', trackerCurb);
        },
        showTrackerInvoice: function(trackerInvoiceId) {
            return !this.state.batchTrackerInvoices.length || this.state.batchTrackerInvoices.includes(trackerInvoiceId);
        }
    },
    computed: {
        trackingProNum: function() {
            return this.trackerData.tracking_pro_num ? `Pro ${this.trackerData.tracking_pro_num}` : '';
        },
        scheduledShipOrLocation: function() {
            return this.trackerData.cdi_location ? this.trackerData.cdi_location : this.trackerData.scheduled_ship;
        },
        showTracker: function() {
            return !this.state.batchTrackers.length || this.state.batchTrackers.includes(this.trackerData.tracker_id);
        },
    },
}
</script>

<style scoped>
    @import '../../../assets/css/buildsch.css';
    .disabled {
        text-decoration: line-through;
    }
</style>