<template>
    <div>
        <nav class="nav_side affix">
            <div class="form-group">
                <label>Location</label>
                <select class="form-control" v-model="state.formFields.cdiLocationID.val" @change="getData">
                    <option value="0">All Locations</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Schedule</label>
                <select class="form-control" v-model="state.formFields.scheduleDate.val" @change="getData">
                    <option value="scheduled_ship">Scheduled Ship</option>
                    <option value="build_date">Build</option>
                    <option value="eng_due_date">Engineering</option>
                </select>
            </div>

            <!-- START EXTRA FILTERS -->
            <div v-show="isMaster()">
                <div>
                    <label><input type="checkbox" v-model="state.formFields.truckLoads.val" :true-value=199 :false-value=0 @change="getData"> Truckloads</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.onHold.val" :true-value=1 :false-value=0 @change="getData"> On Hold</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.hasSpecialMaterials.val" :true-value=1 :false-value=0 @change="getData"> Special Materials</label>
                </div>
            </div>

            <label><input type="checkbox" v-model="state.formFields.curbCounts.val" :true-value=1 :false-value=0 @change="getData"> Item Counts</label>

            <input v-show="!moreFiltersShown" type="button" value="More Filters" class="btn btn-default" @click="showMoreFilters()">

            <div v-show="moreFiltersShown">
                <div class="form-group">
                    <label>Item Type</label>
                    <select class="form-control" @change="getData" v-model="state.formFields.curbTypeID.val">
                        <option value="0">All Types</option>
                        <option v-for="[curbTypeID, curbType] in cache.curbTypeCache.CURB_TYPES" v-bind:key="curbTypeID" :value="curbTypeID">{{ curbType }}</option>
                    </select>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.conforming.val" :true-value=1 :false-value=0 @change="getData"> Conforming</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.applied.val" :true-value=1 :false-value=0 @change="getData"> Applied</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.sectional.val" :true-value=1 :false-value=0 @change="getData"> Sectional</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.insulationKit.val" :true-value=1 :false-value=0 @change="getData"> Insulation Kit</label>
                </div>
                <div v-show="isBatching()" class="form-group">
                    <label><input type="checkbox" v-model="gaugeShown" @change="showGauge"> Show Gauge</label>
                </div>
                <div v-show="isBatching() || isTurret()" class="form-group">
                    <label>Machine Name</label>
                    <select class="form-control" v-model="state.formFields.batchingMachine.val" @change="getData">
                        <option value="0">All</option>
                        <option v-for="(machineName, index) in machineNames" v-bind:key="index" :value="machineName">{{machineName}}</option>
                    </select>
                </div>
            </div>

            <br>
            <br>

            <div v-show="isMaster()">
                <div>
                    <label><input type="checkbox" v-model="state.formFields.curbPoints.val" :true-value=1 :false-value=0 @change="getData"> Show Points</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.shiptoAddress.val" :true-value=1 :false-value=0 @change="getData"> Show Shipping Details</label>
                </div>
                <div>
                    <label><input type="checkbox" v-model="state.formFields.sortState.val" :true-value=1 :false-value=0 @change="getData"> Sort by State</label>
                </div>
            </div>
            <!-- END EXTRA FILTERS -->

            <div v-if="isBatching()">
                <div>
                  <label><input type="checkbox" v-model="state.formFields.shiptoAddress.val" :true-value=1 :false-value=0 @change="getData"> Show Shipping Details</label>
                </div>
                <div class="spacing_top">
                    <label><input type="checkbox" v-model="state.batchMode"> Batch Mode</label>
                </div>
                <div class="batch-container">
                    <div v-for="row in state.buildSchedule.groups" v-bind:key="row.group">
                        <label>
                            <input type="radio" name="batch" :value="row.group" @click="selectBatches(row.group)"> #{{ row.group }}
                        </label>
                    </div>
                </div>

                <div>
                    <input class="btn btn-default" type="button" @click="pickMachineName" value="Batch Items" style="margin-bottom: 10px">
                </div>
            </div>
            <div v-else-if="isTurret()">
                <div class="spacing_top">Batches</div>

                <label><input type="radio" value="0" name="batches" @click="filterTurretScheduler(0)" checked> None</label>

                <div v-for="row in state.buildSchedule.groups" v-bind:key="row.group">
                    <label>
                        <label><input type="radio" @click="filterTurretScheduler(row.group)" :value="row.group" name="batches"> #{{row.group}}</label>
                    </label>
                    &nbsp;<span class="glyphicon glyphicon-pencil cdi_link" @click="openGroupNotesModal(row.group, row.notes)"></span>&nbsp;
                    <span class="tracker_group_notes">{{row.notes}}</span>
                </div>
                <br>
            </div>
            <button class="btn btn-default" @click="setFileUploadParameters">Upload File</button>
             <br/>
            <input class="btn btn-default" type="button" @click="showTrackerKey = true" value="Trackers Key" style="margin-bottom: 10px">
            <Modal v-if="showTrackerKey" @close="showTrackerKey = false" :heading="'Trackers Key'">
                <template v-slot:body>
                    <div class="form-group">
                        <ul class="simple_list legend">
                            <li class="LTL">LTL</li>
                            <li class="truckload">Truckload</li>
                            <li class="flatbed">Flatbed</li>
                            <li class="consolidated">Consolidate</li>
                        </ul>
                    </div>
                </template>
                <template v-slot:footer>
                    <button @click="showTrackerKey = false" class="btn btn-primary">Close</button>
                </template>
            </Modal>
            <input class="btn btn-default" type="button" @click="showItemsKey = true" value="Items Key" style="margin-bottom: 10px">
            <Modal v-if="showItemsKey" @close="showItemsKey = false" :heading="'Items Key'">
                <template v-slot:body>
                    <div class="form-group">
                        <ul class="simple_list legend">
                            <li class="sales">Sales</li>
                            <li class="engineering">Engineering</li>
                            <li class="checking">Checking</li>
                            <li class="radan">Radan</li>
                            <li class="paperwork">Batching</li>
                            <li class="turret">Turret</li>
                            <li class="folding">Folding</li>
                            <li class="clinching">Clinching</li>
                            <li class="shell">Shell</li>
                            <li class="guts">Guts</li>
                            <li class="qc">QC</li>
                            <li class="insulate">Insulate</li>
                            <li class="crate">Crate</li>
                            <li class="loading">Loading</li>
                        </ul>
                    </div>
                </template>
                <template v-slot:footer>
                    <button @click="showItemsKey = false" class="btn btn-primary">Close</button>
                </template>
            </Modal>
        </nav>

        <Modal v-if="showBatchModal" @close="closeBatchModal" :heading="'Batch Items?'">
            <template v-slot:body>
                <div class="form-group">
                    <label>
                        Verify which machine will be used
                        <span v-if="state.batchGroup">for batch # {{ state.batchGroup }}</span>
                    </label>
                    <select class="form-control" v-model="batchMachineChosen">
                        <option v-for="(machineName, index) in machineChoices" v-bind:key="index" :value="machineName">{{machineName}}</option>
                    </select>
                </div>
            </template>
            <template v-slot:footer>
                <button @click="batchCurbs" class="btn btn-primary">Batch</button>
            </template>
        </Modal>

        <Modal v-if="notesGroupChosen" @close="closeGroupNotesModal" :heading="`Batch #${notesGroupChosen} Notes`">
            <template v-slot:body>
                <em>(50 character limit)</em>

                <div class="form-group">
                    <label>Notes</label>
                    <textarea class="form-control vertical-resize" v-model="groupNotes"></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <button class="btn btn-primary" @click="updateGroupNotes">Update note</button>
            </template>
        </Modal>

        <span class="well well-sm" v-show="false">
            Refreshing in
            <span v-show="refreshTime > 0">{{ timeLeft }}</span>
            <span v-show="refreshTime === 0" class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
        </span>
    </div>
</template>

<script>
import { store } from '@/store/BuildScheduler.store';
import helpers from "@/store/helpers";
import cdiLocationCache from '@/cache/cdiLocation.cache';
import curbTypeCache from '@/cache/curbType.cache.js';
import Modal from "@/components/utils/Modal";
import AppStore from "@/store/App.store";

export default {
    name: "Nav",
    components: {
        Modal
    },
    data() {
        return {
            state: store.state,
            baseUrl: process.env.VUE_APP_URL,
            cache: {
                cdiLocationCache,
                curbTypeCache
            },
            showBatchModal: false,
            showTrackerKey: false,
            showItemsKey: false,
            machineChoices: {},
            batchMachineChosen: '',
            notesGroupChosen: null,
            groupNotes: null,
            refreshTime: 120000,
            moreFiltersShown: false,
            timeouts: [],
            gaugeShown: false,
            cdiLocationID : AppStore.state.user.schedulerLocation
        }
    },
    props: ['task', 'machineNames'],
    created() {
        this.timeouts.push(
            window.setTimeout(this.countdownRefresh(), 5000)
        );
    },
    methods: {
        showGauge: function (){
            this.state.showGauge = this.gaugeShown;
        },
        getData: function() {
            store.getData();
        },
        isMaster: function () {
            return this.task.taskID === this.state.tasks.master.taskID;
        },
        isBatching: function () {
            return this.task.taskID === this.state.tasks.batching.taskID;
        },
        isTurret: function () {
            return this.task.taskID === this.state.tasks.turret.taskID;
        },
        countdownRefresh: function () {
            if (this.refreshTime === 0) {
                this.getData();
                this.refreshTime = 120000;
            }

            this.timeouts.push(
                window.setTimeout(this.countdownRefresh, 2000)
            );
            this.refreshTime = this.refreshTime - 2000;
        },
        showMoreFilters: function () {
            this.moreFiltersShown = true;
        },
        selectBatches: function (batchID) {
            this.state.curbsToBatch = this.state.buildSchedule.batches[batchID].tracker_invoice_ids;
            this.state.batchGroup = batchID;
        },
        filterTurretScheduler: function (batchID) {
            if (!batchID) {
                this.state.batchTrackers = [];
                this.state.batchTrackerInvoices = [];
            } else {
                this.state.batchTrackers = this.state.buildSchedule.batches[batchID].tracker_ids;
                this.state.batchTrackerInvoices = this.state.buildSchedule.batches[batchID].tracker_invoice_ids;
            }
        },
        openGroupNotesModal: function (groupID, notes) {
            this.notesGroupChosen = groupID;
            this.groupNotes = notes;
        },
        updateGroupNotes: function () {
            let options = {
                'params[notes]': this.groupNotes,
                'params[tracker_group_id]': this.notesGroupChosen
            };

            store.api('update_tracker_group_notes', options).then(() => {
                this.getData();
                this.closeGroupNotesModal();
            });
        },
        closeGroupNotesModal: function () {
            this.notesGroupChosen = null;
            this.groupNotes = null;
        },
        pickMachineName: function () {
            let options = {
                'params[cdi_location_id]': this.state.formFields.cdiLocationID.val
            };

            store.api('get_batching_machine_names', options).then(result => {
                this.machineChoices = result;
                this.batchMachineChosen = Object.values(this.machineChoices)[0];
            });
        },
        batchCurbs: function () {
            let options = {
                'params[machine_name]': this.batchMachineChosen,
                'params[batch_ids]': this.state.curbsToBatch,
                ...store.getFilterParameters()
            };
            if (this.state.batchGroup > 0) {
                options['params[tracker_group_id]'] = this.state.batchGroup
            }

            let localThis = this;
            store.api('batch_items', options).then(result => {
                if (result.opt !== 'error') {
                    helpers.nestedSet(this.state, 'buildSchedule', result);

                }
                store.customAPI('/mycdi/has_valid_file_access_token', {
                    headers: helpers.authHeader(),
                    'params[token]': localThis.$appStore.getUserFileAccessToken()
                }).then(result => {
                    if (!result.valid) {
                        store.customAPI('/mycdi/regenerate_file_access_token', {headers: helpers.authHeader()}).then(result => {
                            localThis.$appStore.setUserFileAccessToken(result.token);
                            options['params[token]'] = result.token;
                            window.open(`${this.baseUrl}/api/buildsch/generate_radan_csv?${helpers.getQSString(options)}`);
                        });
                    } else {
                        options['params[token]'] = localThis.$appStore.getUserFileAccessToken();
                        window.open(`${this.baseUrl}/api/buildsch/generate_radan_csv?${helpers.getQSString(options)}`);
                    }


                }).finally(() => {
                    this.$appStore.state.appMessage = '';
                });

                this.state.curbsToBatch = [];
                this.state.batchGroup = null;
                this.closeBatchModal();
            });
        },
        closeBatchModal: function () {
            this.machineChoices = {};
            this.batchMachineChosen = '';
        },
        setFileUploadParameters: function () {
            this.$appStore.setFileUploadContext('item_batch');
            this.$appStore.setFileUploadContextData({
                referenceID: this.state.batchGroup
            });
            this.$appStore.setFileUploadCallback(function () {});
        }
    },
    computed: {
        timeLeft: function () {
            let time = this.refreshTime;
            let minutes = Math.floor(time / 1000 / 60);
            let seconds = (time / 1000) - (minutes * 60);

            return `${minutes}m ${seconds}s`;
        },
        checkTask: function () {
            return this.task.taskID;
        }
    },
    watch: {
        machineChoices: function () {
            this.showBatchModal = Object.keys(this.machineChoices).length > 0;
        },
        checkTask: function () {
                this.state.batchMode = false;
                this.state.batchGroup = null;
                this.state.batchTrackers =[];
                this.state.batchTrackerInvoices = [];
        }

    },
    destroyed() {
        for (let i = 0; i < this.timeouts.length; i++) {
            window.clearTimeout(this.timeouts[i]);
        }
    }
}
</script>

<style scoped>
.fileUploadContainer {
    margin-top: 10px;
    width: 100%!important;
    height: 75px;
    line-height: 50px;
    position: static;
}

@media only screen and (min-width: 768px) {
    .affix {
        max-width: 215px;
        padding-bottom: 25px;
    }
}

.affix{
    background-color: #FFFFFF;
    padding: 10px;
    left: 0px;
    height: 100%;
    border-right: 1px solid #D4D8DD;
    overflow-y: scroll;
    padding-bottom: 250px;
}

.batch-container {
    text-align: left;
    max-height: 40vh;
    overflow-y: auto;
    border-style: dashed;
    border-width: 1px;
    border-color: #8c8c8c;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    padding-left: 5px;
}
@media only screen and (max-width: 768px) {
    .affix {
        padding-bottom: 25px;
    }
}

</style>
