<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <div class="well well-sm">
                Refreshing in
                <span v-show="refreshTime > 0">{{ timeLeft }}</span>
                <span v-show="refreshTime === 0" class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
            </div>

            <div class="form-group">
                <label>Location</label>
                <select class="form-control" v-model="drawScheduleState.formFields.cdiLocationID.val" @change="getData">
                    <option value="0">All Locations</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
        </nav>
    </div>
</template>

<script>
    import { store } from '@/store/Tool.store';
    import cdiLocationCache from '@/cache/cdiLocation.cache';
    import { drawScheduleStore } from '@/store/DrawScheduler.store.js';
    import helpers from "@/store/helpers";
    import AppStore from "@/store/App.store";

    export default {
        name: "Nav",
        data() {
            return {
                state: store.state,
                drawScheduleState: drawScheduleStore.state,
                cache: {
                    cdiLocationCache
                },
                refreshTime: 120000,
                timeouts: []
            }
        },
        created() {
            this.drawScheduleState.formFields.cdiLocationID.val = AppStore.state.user.schedulerLocation
            this.getData();
            this.countdownRefresh();
        },
        methods: {
            getData: function() {
                store.api('/drawsch/open_requests', helpers.getQSPairs(this.drawScheduleState.formFields))
                    .then(results => this.drawScheduleState.data = results);
            },
            countdownRefresh: function () {
                if (this.refreshTime === 0) {
                    this.getData();
                    this.refreshTime = 120000;
                }

                this.timeouts.push(
                    window.setTimeout(this.countdownRefresh, 2000)
                );
                this.refreshTime = this.refreshTime - 2000;
            },
        },
        computed: {
            timeLeft: function () {
                let time = this.refreshTime;
                let minutes = Math.floor(time / 1000 / 60);
                let seconds = (time / 1000) - (minutes * 60);

                return `${minutes}m ${seconds}s`;
            }
        },
        watch: {
            startCountdown: function () {
                this.timeouts.push(
                    window.setTimeout(this.countdownRefresh(), 5000)
                );
            }
        },
        destroyed() {
            for (let i = 0; i < this.timeouts.length; i++) {
                window.clearTimeout(this.timeouts[i]);
            }
        }
    }
</script>

<style scoped>

</style>