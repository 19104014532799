<template>
    <div>
      <div class="table-container">
        <table class="sch5day">
            <tr>
                <td v-for="(day, id) in buildSchedule.days" :key="id">
                    <div class="day_header">{{ day.schedule_date }}</div>
                    <p v-if='task.taskID == 0 && (appStore.hasRole([cdiVars.CDI_ROLE_SUPER, cdiVars.CDI_ROLE_LEADERSHIP, cdiVars.CDI_ROLE_MANAGER]))' class="btn-link" @click="moreLaborInfo = !moreLaborInfo"><span><strong>Labor Available:</strong> ${{ Math.round(day.labor_dollars_available).toLocaleString('en') }}</span></p>
                    <div v-show="moreLaborInfo && task.taskID == 0">
                        <div class="border-left">
                            <p>Build Driven Labor</p>
                            <table v-show="moreLaborInfo" class="table">
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <th>Labor Rate Goal</th>
                                    <td class="number">{{ day.labor_goal.toLocaleString() }}%</td>
                                </tr>
                                <tr>
                                    <th>Scheduler Remaining</th>
                                    <td class="number">${{ Math.round(day.revenue).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Labor Available</th>
                                    <td class="number">${{ Math.round(day.labor_dollars_available).toLocaleString('en') }}</td>
                                </tr>
                                <tr >
                                    <th>Active Hourly Rate</th>
                                    <td class="number">${{ Math.round(day.active_hourly_rate ).toLocaleString('en') }}</td>
                                </tr>
                                </tbody>
                            </table>
                            <p>Schedule Ship Driven Labor</p>
                            <table v-show="moreLaborInfo" class="table">
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <th>Total Scheduled Sales</th>
                                    <td class="number">${{ Math.round(day.total_day_sales).toLocaleString() }}</td>
                                </tr>
                                <tr >
                                    <th>Daily Labor Available</th>
                                    <td class="number">${{ Math.round(day.total_day_labor).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Labor Consumed</th>
                                    <td class="number">${{ Math.round(day.labor_burned).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Labor Remaining</th>
                                    <td class="number">${{ Math.round(day.total_day_labor - day.labor_burned).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Over/Under Labor Goal</th>
                                    <td class="number">${{ Math.round((day.total_day_labor - day.labor_burned) - day.labor_dollars_available).toLocaleString() }}</td>
                                </tr>
                                <tr>
                                    <th>Sales Shipped Today</th>
                                    <td class="number">${{ Math.round(day.sales_completed).toLocaleString() }}</td>
                                </tr>
                                <tr v-if="day.sales_completed">
                                    <th>Estimated Labor Rate</th>
                                    <td class="number">{{ ((day.labor_burned / day.sales_completed) * 100).toFixed(2).toLocaleString() }}%</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div v-show="day.curb_type_qty" class='day_sub_header well well-sm'>{{ day.curb_type_qty }}</div>
                    <Tracker v-for="(tracker, key) in day.trackers" :key="key"
                             :trackerData="tracker"
                             v-on:taskDialog="showTaskDialog"
                             v-on:stockDialog="showStockDialog"
                    />
                </td>
            </tr>
        </table>
        </div>

        <TaskDialog :taskID="task.taskID" :userID="user.userID" :trackerCurb="activeTrackerCurb" v-on:closeTaskDialog="closeTaskDialog" />
        <CurbStockDialog :user="user" :trackerCurb="activeStockCurb" v-on:closeStockDialog="closeStockDialog" />
    </div>
</template>

<script>
import Tracker from "@/components/schedulers/buildsch/Tracker";
import TaskDialog from "@/components/schedulers/buildsch/TaskDialog";
import CurbStockDialog from "@/components/schedulers/buildsch/CurbStockDialog";
import {store} from "@/store/BuildScheduler.store";
import appStore from "@/store/App.store";
import {cdiVars} from "@/cdiVars";

export default {
    name: "Container",
    computed: {
        cdiVars() {
            return cdiVars
        },
        appStore() {
            return appStore
        }
    },
    components: {
        CurbStockDialog,
        Tracker,
        TaskDialog
    },
    data() {
        return {
            state: store.state,
            activeTrackerCurb: {},
            activeStockCurb: {},
            moreLaborInfo: false
        }
    },
    props: ['buildSchedule', 'task', 'user'],
    methods: {
        showTaskDialog: function (trackerCurb) {
            this.activeTrackerCurb = trackerCurb;
            let tableContainer = document.querySelector('.table-container');
            tableContainer.classList.add('active');

        },
        closeTaskDialog: function () {
            this.activeTrackerCurb = {};
            this.state.batchGroup = null;
            this.state.curbsToBatch = [];
            let tableContainer = document.querySelector('.table-container');
            tableContainer.classList.remove('active');
        },
        showStockDialog: function (trackerCurb) {
            this.activeStockCurb = trackerCurb;
        },
        closeStockDialog: function () {
            this.activeStockCurb = {};
        }
    }
}
</script>

<style scoped>
    @import '../../../assets/css/buildsch.css';

    .btn-link {
        cursor: pointer;
    }

    .border-left {
        border-left: lightskyblue;
        border-left-style: solid;
        padding: 10px;
    }

    .table-container.active {
        overflow-x: auto;
        overflow-y: scroll;
        width: 100%;
        max-width: 100vw;
        padding-bottom: 20px;
    }

</style>