<template>
    <div>
        <Modal :classes="`taskDialog`" v-if="trackerCurb" v-show="show" @close="close()" :heading="`${trackerCurb.line} ${trackerCurb.nomenclature}`">
            <template v-slot:body>
                <div title="Item Action">
                    <form v-if="taskID !== 0">
                        <h3>Task Action</h3>
                        <div class="form-inline">
                            <label>User #</label>
                            <input type="text" v-model="formUserID" class="form-control">
                            <label><input type=radio value="start" v-model="formOption"> Start</label>&nbsp;
                            <label><input type=radio value="stop" v-model="formOption"> Pause</label>&nbsp;
                            <label><input type=radio value="complete" v-model="formOption" checked> Complete</label>&nbsp;
                            <input class="btn btn-default" type="button" @click="save" value="Save Task">
                        </div>
                    </form>
                    <div v-if="taskID === 8 /* CRATING */" class="margin_top text-center">
                        <button @click="openPhotoComponent" class="btn btn-primary"><span class="glyphicon glyphicon-camera camera-icon"></span></button>
                    </div>
                    <h3>View / Print PDFs</h3>
                    <ul>
                        <li>
                            <a target="_pdf" :href="pdfEndpoint('curb_stickers', true)">Item Stickers</a>
                        </li>
                        <li>
                            <a target="_pdf" :href="pdfEndpoint('curb_stickers_nomenclature', true)">Item Barcodes</a>
                        </li>
                        <li>
                            <a target="_pdf" :href="pdfEndpoint('cover_sheets')">Cover Sheets</a>
                        </li>
                        <li>
                            <a target="_pdf" :href="pdfEndpoint('shipping_labels', true)">Shipping Label</a>
                        </li>
                        <li>
                            <a target="_pdf" :href="pdfEndpoint('shipping')">Shipping Paperwork</a>
                        </li>
                        <li v-for="drawing in extraDrawings" v-bind:key="drawing.cdi_file_id">
                            <a target="_blank" :href="`${baseUrl}/api/download/file/${drawing.cdi_file_id}?token=${globalAppStore.getUserFileAccessToken()}`">
                                {{ drawing.title }}
                            </a>
                        </li>
                    </ul>

                    <button type="button" class="cdi_button btn btn-default" @click="getDrawings" v-show="extraDrawings.length === 0">More PDFs</button>

                    <form v-show="showProForm() && [0, 8, 14].includes(taskID)">
                        <h3>Save Shipping Pro</h3>
                        <div class="form-inline">
                            <label>Pro #</label>
                            <input type="text" v-model="formTrackingProNumber" class="form-control">
                            <input class="btn btn-default" type="button" @click="saveTrackerPro" value="Save Pro">
                        </div>
                    </form>
                </div>
            </template>
        </Modal>

        <Modal :heading="'Rework'" v-if="showRework">
            <template v-slot:body>
                <form class="rework_form">
                    <div><label>Metal</label></div>
                        <div class="form-group col-md-4">
                            <label>*Length</label> <input type="text" v-model="reworkFormFields.steelLength1.val" class="form-control">
                        </div>
                        <div class="form-group col-md-4">
                            <label>*Width</label> <input type="text" v-model="reworkFormFields.steelWidth1.val" class="form-control">
                        </div>
                        <div class="form-group col-md-4">
                            <label>*Gauge</label>
                            <select class="form-control" v-model="reworkFormFields.steelGauge1.val">
                                <option v-for="[curbTypeId, curbType] in cache.curbTypeCache.CURB_GAUGES" v-bind:key="curbTypeId" :value="curbTypeId">{{ curbType }}</option>
                            </select>
                        </div>

                    <div class="spacing_btm">
                        <div class="form-group col-md-4">
                        <label>Length </label> <input type="text" v-model="reworkFormFields.steelLength2.val" class="form-control">
                        </div>
                            <div class="form-group col-md-4">
                            <label>Width </label> <input type="text" v-model="reworkFormFields.steelWidth2.val" class="form-control">
                            </div>
                        <div class="form-group col-md-4">
                                <label>Gauge </label>
                        <select class="form-control" v-model="reworkFormFields.steelGauge2.val">
                            <option v-for="[curbTypeId, curbType] in cache.curbTypeCache.CURB_GAUGES" v-bind:key="curbTypeId" :value="curbTypeId">{{ curbType }}</option>
                        </select>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <label>Angle Iron 1-1/4"</label> <input type="text" v-model="reworkFormFields.angleIron1.val" class="form-control"> Inches
                    </div>
                    <div class="form-group col-md-6 spacing_btm">
                        <label>Angle Iron 2"</label> <input type="text" v-model="reworkFormFields.angleIron2.val" class="form-control"> Inches
                    </div>

                    <div><label>Insulation</label></div>
                    <div class="form-group col-md-6">
                        <label>Length</label> <input type="text" v-model="reworkFormFields.insulateLength1.val" class="form-control">
                        <label>Width</label> <input type="text" v-model="reworkFormFields.insulateWidth1.val" class="form-control">
                    </div>
                    <div class="form-group col-md-6 spacing_btm">
                        <label>Length</label> <input type="text" v-model="reworkFormFields.insulateLength2.val" class="form-control">
                        <label>Width</label> <input type="text" v-model="reworkFormFields.insulateWidth2.val" class="form-control">
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <button class="cdi_button btn btn-primary" type="button" @click="addReworkMaterials()">Add Material</button>
            </template>
        </Modal>

      <Modal :classes="'photoComponent'" :heading="'Curb Photos'" @close="closePhotoComponent()" v-if="isPhotoComponentOpen">
        <template v-slot:body>
          <Camera
              v-if="isPhotoComponentOpen"
              :file_type_id="$cdiVars.FILE_TYPE_TRACKER_PHT"
              :cdi_reference_id="barcodePieces.trackerID"
              :cdi_reference2_id="barcodePieces.trackerInvoiceID"
              @closePhotoComponent="closePhotoComponent"
          />
        </template>
      </Modal>

        <ConfirmBox
            v-if="confirmBox"
            :heading="'Task Already Started'"
            :body="confirmBoxMessage"
            @close="forceSave"
        />

    </div>
</template>

<script>
    import { store } from '@/store/BuildScheduler.store';
    import ConfirmBox from "@/components/utils/ConfirmBox";
    import helpers from "@/store/helpers";
    import Modal from '@/components/utils/Modal';
    import curbTypeCache from '@/cache/curbType.cache.js';
    import appStore from "@/store/App.store";
    import Camera from "@/components/utils/Camera.vue";

    export default {
        name: "TaskDialog",
        components: {
            ConfirmBox,
            Modal,
            Camera
        },
        props: ['taskID', 'userID', 'trackerCurb'],
        data() {
            return {
                state: store.state,
                show: false,
                showRework: false,
                isPhotoComponentOpen: false,
                reworkFormFields: {
                    steelLength1: { val: '', qsField: '[steel][length1]' },
                    steelWidth1: { val: '', qsField: '[steel][width1]' },
                    steelGauge1: { val: 18, qsField: '[steel][gauge1]' },
                    steelLength2: { val: '', qsField: '[steel][length2]' },
                    steelWidth2: { val: '', qsField: '[steel][width2]' },
                    steelGauge2: { val: 18, qsField: '[steel][gauge2]' },
                    angleIron1: { val: '', qsField: '[angle_iron1]' },
                    angleIron2: { val: '', qsField: '[angle_iron2]' },
                    insulateLength1: { val: '', qsField: '[insulate][length1]' },
                    insulateWidth1: { val: '', qsField: '[insulate][width1]' },
                    insulateLength2: { val: '', qsField: '[insulate][length2]' },
                    insulateWidth2: { val: '', qsField: '[insulate][width2]' }
                },
                confirmBox: false,
                confirmBoxMessage: null,
                formTrackingProNumber: null,
                formOption: 'start',
                formUserID: this.userID,
                extraDrawings: [],
                barcodePieces: [],
                cache: {
                    curbTypeCache
                },
                globalAppStore: appStore
            }
        },
        methods: {

            save: function () {
                let options = this.getSaveParameters();

                store.api('do_task_json', options).then(result => {
                    // Refresh DOM
                    helpers.nestedSet(this.state, 'buildSchedule', result);

                    switch (result.opt) {
                        case 'confirm':
                            this.confirmBox = true;
                            this.confirmBoxMessage = result.msg;

                            return;
                        case 'rework':
                            this.showRework = true;

                            return;
                    }

                    this.close();
                });
            },
            forceSave: function (confirm) {
                if (!confirm) {
                    this.close();

                    return;
                }

                let options = this.getSaveParameters();
                options['params[force]'] = 1;

                store.api('do_task_json', options, 'buildSchedule');
                this.close();
            },
            getSaveParameters: function () {
                return {
                    'params[user_id]': this.formUserID,
                    'params[opt]': this.formOption,
                    'params[tracker_curb_code]': this.trackerCurb.barcode,
                    'params[task_id]': this.taskID,
                    ...store.getFilterParameters()
                };
            },
            close: function () {
                this.extraDrawings = [];
                this.barcodePieces = [];
                this.showRework = false;
                this.reworkFormFields = {
                    steelLength1: { val: '', qsField: '[steel][length1]' },
                    steelWidth1: { val: '', qsField: '[steel][width1]' },
                    steelGauge1: { val: 18, qsField: '[steel][gauge1]' },
                    steelLength2: { val: '', qsField: '[steel][length2]' },
                    steelWidth2: { val: '', qsField: '[steel][width2]' },
                    steelGauge2: { val: 18, qsField: '[steel][gauge2]' },
                    angleIron1: { val: '', qsField: '[angle_iron1]' },
                    angleIron2: { val: '', qsField: '[angle_iron2]' },
                    insulateLength1: { val: '', qsField: '[insulate][length1]' },
                    insulateWidth1: { val: '', qsField: '[insulate][width1]' },
                    insulateLength2: { val: '', qsField: '[insulate][length2]' },
                    insulateWidth2: { val: '', qsField: '[insulate][width2]' }
                };
                this.confirmBox = false;
                this.confirmBoxMessage = null;
                this.$emit('closeTaskDialog');
            },
            saveTrackerPro: function () {
                let params = {'params[tracking_pro_num]': this.formTrackingProNumber};
                store.customAPI('tracker/save_tracker_pro/' + this.barcodePieces.trackerID, params).then(result => {
                    if (result.opt === 'error') {
                        return;
                    }

                    this.trackerCurb.tracking_pro_num = this.formTrackingProNumber;
                    this.formTrackingProNumber = null;
                });
            },
            getDrawings: function () {
                store.customAPI('tracker_curb/to_json_drawings/' + this.barcodePieces.trackerInvoiceID).then(result => {
                    this.extraDrawings = result.drawings;
                });
            },
            showProForm: function () {
                let number = this.trackerCurb.tracking_pro_num;

                if (typeof number === 'undefined') {
                    return true;
                }

                return number === '';
            },
            pdfEndpoint: function (endPoint, includeLine = false) {
                let trackerID = this.barcodePieces.trackerID;
                let text = `${this.trackerCurb.line} ${this.trackerCurb.nomenclature}`;

                let url = `${this.baseUrl}/api/tracker_pdf/${endPoint}/${trackerID}`;

                if (includeLine) {
                    url += `?line=${text}`;
                }

                return url;
            },
            addReworkMaterials: function () {
                let options = {
                    ...helpers.getQSPairs(this.reworkFormFields),
                    ...store.getFilterParameters(),
                    'params[tracker_invoice_id]': this.barcodePieces.trackerInvoiceID
                };
                if (!this.reworkFormFields.steelLength1.val || !this.reworkFormFields.steelWidth1.val  || !this.reworkFormFields.steelGauge1.val) {
                    this.$appStore.errorBox('You must fill out required fields.');
                }else{
                    store.api('save_rework_materials', options).then(result => helpers.nestedSet(this.state, 'buildSchedule', result));
                    this.close();
                }
            },
            openPhotoComponent() {
                this.isPhotoComponentOpen = true;
            },
            closePhotoComponent(photoCount) {
                this.isPhotoComponentOpen = false;
                this.photoCount = photoCount;
            }
        },
        computed: {
            baseUrl: function () {
                return process.env.VUE_APP_URL;
            },
            queryString: function() {
                const params = helpers.getQSString({ 'params' : this.companyId});
                return `genid=${Math.floor(Math.random()*101)}&${params}`;
            },
        },
        watch: {
            trackerCurb: function () {
                this.extraDrawings = [];
                this.show = this.trackerCurb.tracker_invoice_id > 0;

                if (this.trackerCurb.barcode) {
                    this.barcodePieces = store.explodeBarcode(this.trackerCurb.barcode);
                }
            }
        }
    }
</script>

<style scoped>
    .rework_form > .form-inline > input {
        width: 60px;
    }
    .margin_top{
        padding-top: 25px;
    }
    .camera-icon {
        font-size: 5em;
    }
</style>